.mainwrapper{
    width:100vw;
    height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat:no-repeat;
    background-size: cover;
    position: relative;
    background-image: url("../images/memes/bg.png"); 
    /* overflow: hidden; */
}
  
  .subwrapper{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    padding:10px;
    
  }

  .body{
    display: flex;
    gap: 10px;
    width: 100%;
    height:100%;
    box-sizing: border-box;
    flex-direction: column;
  }

  .banner{
    position: absolute;
    width: 100%;
    left: 0;
    object-fit:cover;
    z-index: 1;
  }

  .imageswrapper{
    display: grid;
    flex: 1;
    flex-direction: column;
    padding: 10px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    justify-items: center;
  }

  .imagewrapper{
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    justify-content:flex-start;
    align-items:flex-end;
    align-self: center;
    background-color: #ffecd3;
    border-radius: 10px;
    border: 4px solid black;
  }

  .image{
    /* z-index: -1; */
    width: 100%;
    object-fit: contain;
    box-sizing: border-box;
    animation: swing 1s cubic-bezier(0.6, -0.28, 0.74, 0.05) infinite;
  }

  .bg{
    object-fit: contain;
    height: 100%;
    position: absolute;
    /* content: url("../images/Section2/bg.png"); */
  }

  .title{
    color: #ffecd3;
    font-family: "comictoon";
    margin: 0;
    -webkit-text-stroke: 5px black;
  }

  .image_title{
    color: black;
    font-family: "comictoon";
    margin: 0;
    z-index: 1;
  }

  .image_subtitle{
    color: black;
    font-family: "Patrick Hand";
    margin: 0;
    z-index: 1;
  }

  .text_wrapper{
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: flex-end;
  }

  @media screen and (max-width:500px) {

    .mainwrapper{
        max-height:1550px;
        min-height:1500px;
        background-image: url("../images/memes/bg.png"); 
    }

    .body{
        padding: 30px;
        gap:25px;
    }

    .banner{
        height: 75px;
    }

    .imageswrapper{
        grid-template-columns: 1fr;
        gap: 24px;
    }

    .imagewrapper{
        width:250px;
        height:300px 
    }
    
    .image{
        /* width:250px; */
        border-width: 10px;
    }

    .title{
        font-size: 70px;
        -webkit-text-stroke: 2px black;
    }

    .banner{
        height:20px;
        top: -10px;
    }

    .TD_icon{
        width: 20px;
        height: 20px;
        object-fit: contain;
    }

  }

  @media screen and (min-width:501px) and (max-width:1023px) {

    .mainwrapper{
        max-height:750px;
        min-height:700px;
        background-image: url("../images/memes/bg.png"); 
    }

    .body{
        padding: 30px;
        gap:25px;
    }

    .imageswrapper{
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 30px;
    }

    .imagewrapper{
        width:200px;
        height:250px;
    }
    
    .image{
        /* width:200px; */
        border-width: 10px;
    }

    .title{
        font-size: 80px;
        -webkit-text-stroke: 3px black;
    }

    .banner{
        height:40px;
        top: -20px;
    }

    .TD_icon{
        width: 24px;
        height: 24px;
        object-fit: contain;
    }

  }

  @media screen and (min-width:1024px) and (max-width:1920px) {

    .mainwrapper{
      max-height: 700px;
      min-height: 720px;
      background-image: url("../images/memes/bg.png"); 
    }

    .body{
        gap: 75px;
    }

    .imageswrapper{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        /* grid-template-rows: 1fr 1fr; */
        gap: 30px;
    }

    .imagewrapper{
        width: 250px; 
        height: 320px;
    }
    
    .image{
        /* width: 300px; */
        border-width: 10px;
    }

    .title{
        font-size: 120px;
        padding-top: 50px;
    }

    .banner{
        height:80px;
        top: -40px;
    }

    .image_title{
        font-size: 34px;
    }
    
    .image_subtitle{
    font-size:28px;
    }

    .TD_icon{
        width: 30px;
        height: 30px;
        object-fit: contain;
    }

  }

  @media screen and (min-width:1921px){

    .mainwrapper{
        max-height: 1000px;
        min-height: 1050px;
        background-image: url("../images/memes/bg.png");    
      }

    .subwrapper{
        max-width: 2200px;
    }

    .body{
        padding: 100px;
        gap: 75px;
    }

    .imageswrapper{
        grid-template-columns: 1fr 1fr 1fr 1fr;
        /* grid-template-rows: 1fr 1fr; */
        gap: 50px;
    }

    .imagewrapper{
        width:420px;
        height:500px;
    }
    
    .image{
        border-width: 10px;
    }  

    .title{
        font-size: 140px;
    }

    .banner{
        height:110px;
        top: -55px;
    }

    .image_title{
        font-size: 40px;
    }
    
    .image_subtitle{
    font-size: 34px;
    }

    .TD_icon{
        width: 30px;
        height: 30px;
        object-fit: contain;
    }

  }

  @keyframes swing {
    0% {
      transform: scale(1);
    }
    /* 25% {
      transform: rotate(5deg);
    } */
    50% {
        transform: scale(1.05);
    }
    /* 75% {
      transform: rotate(-5deg);
    } */
    100% {
        transform: scale(1);
    }
  }

