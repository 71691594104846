@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand+SC&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Patrick+Hand:wght@400&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    --font-purejoy: "Purejoy";
    --font-comictoon:"comictoon";
    --font-comicjungleextrude: "comicjungleextrude";
    --font-patrick-hand: "Patrick Hand";
    --font-patrick-hand-SC: "Patrick Hand SC";
}


@font-face {
  font-family: "comicjungleextrude";
  src: url("/public/comicjungleextrude.ttf");
  font-weight: 400;
}

@font-face {
  font-family:"comictoon";
  src: url("/public/Comictoon.otf");
  font-weight: 400;
}

@font-face {
  font-family: "purejoy";
  src: url("/public/Purejoy.ttf");
  font-weight: 400;
}


@font-face {
  font-family: "snackercomic";
  src: url("/public/SnackerComic.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "boldsline";
  src: url("/public/Boldsine.otf");
  font-weight: 400;
}
